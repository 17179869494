import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Site } from '../domain/site';
import { IHvac } from './equipment.service';
import { UtilsService } from './utils.service';

export interface ISite {
  id: number,
  name: string,
  address: string,
  zip_code: string,
  city: string,
  suspend_calculation: Site
  dealer?: string,
  imageUrl?: string,
  latitude?: number,
  longitude?: number,
  nen2580UsableFloorspace?: string,
  primaryWeiiBuildingType?: string,
  create_at?: string,
  updated_at?: string,
  quality_delay?: number,
  quality_no_monitoring_reason?: string,
}

@Injectable({
    providedIn: 'root'
})
export class SiteService {

  constructor(
    private httpClient: HttpClient,
    public router: Router
  ) {}

  public getAll(): Observable<ISite[]> {
    return this.httpClient.get<ISite[]>('/api/users/projects');
  }

  public getSiteIdsRelatedToUser(userName: string): Observable<ISite['id'][]> {
    const httpParams = new HttpParams({
      fromObject: {
        userName: userName
      }
    });
    return this.httpClient.get<ISite['id'][]>('/api/users/hasProjects', { params: httpParams });
  }

  public getEmptySite(): ISite {
    return {
      id: -1,
      name: '',
      address: '',
      zip_code: '',
      city: '',
      suspend_calculation: Site.valueForCalculationInactive,
      latitude: 0,
      longitude: 0,
      nen2580UsableFloorspace: ''
    };
  }

  public getHvacIds(siteId: number): Observable<IHvac[]> {
    const params = new HttpParams().set('siteId', siteId.toString());
    return this.httpClient.get<IHvac[]>('/api/st/hvac', { params })
  }


  public update(id, location): Observable<ISite> {
    return this.httpClient.put<ISite>('/api/portal/sites/' + id, location).pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    return throwError(error);
  }

  public getSiteById(id: string): Observable<ISite> {
    return this.httpClient.get<ISite[]>('/api/portal/sites')
          .pipe(map(data => data.find(s => +s.id === +id)));
  }

  public getSiteNameById(id: string): Observable<ISite['name']> {
    return this.httpClient.get<ISite[]>('/api/portal/sites')
          .pipe(map(data => data.find(s => +s.id === +id)['name']));
  }

  public getSitesByCurrentUser(): Observable<any> {
    return this.httpClient.get<any>('/api/portal/sites', {})
      .pipe(map(data =>
          data.sort((a: any, b: any) =>
            a.name.toString().localeCompare(b.name))
      ));
  }

  public getDefaultSiteId(): Observable<string> {
    return this.getSitesByCurrentUser().pipe(
      map(sites => {
        sites = UtilsService.sortObjectsByProperty(sites, 'id', false);
        return '' + sites[0]['id'];
      })
    );
  }
}
